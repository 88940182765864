export class AdalAuthenticator {
    constructor(config) {
        this.user = null;
        this.config = {
            mandatoryAuth: true,
            callback: this.authCallback.bind(this),
            ...config
        };

        this.authenticationContext = new AuthenticationContext(this.config);
        if (this.authenticationContext.isCallback(window.location.hash)) {
            this.authenticationContext.handleWindowCallback();
        }
    }

    login() {
        var user = this.authenticationContext.getCachedUser();
        if (user) {
            this.user = user;
            if (!this.checkRoles("Admin") && !this.checkRoles("User")) {
                location.href = '/401.html';
            }
        }
        if (!user) {
            if (this.config.mandatoryAuth) {
                this.authenticationContext.login();
                this.authenticationContext.acquireToken(this.config.clientId, this.tokenAcquired.bind(this));
            }
        }
    }

    logOut() {
        this.authenticationContext.logOut();
    }

    authCallback(errorDesc, token, error, tokenType) {
    }

    tokenAcquired(errorDesc, token, error) {
        if (error) {
            this.authenticationContext.acquireTokenRedirect(this.config.clientId, null, null);
        } else {
            this.token = token;
        }
    }
    
    fetchToken(url) {
        const resource = this.config.resources.find(r => url.startsWith(r.url));
        const resourceId = resource && resource.resourceId || this.config.clientId;

        let token = this.authenticationContext.getCachedToken(resourceId);

        return new Promise((resolve, reject) => {
            if (token === null) {
                this.authenticationContext.acquireToken(resourceId, (error, token) => token ? resolve(token) : reject(error));
            } else {
                resolve(token);
            }
        }).catch(console.error);
    }

    authenticatedFetch(url, options) {
        const headers = Object.assign({}, options && options.headers || {});

        if (options && options.headers) {
            delete options.headers;
        }
        
        return fetchToken(url).then(t => {
            const fetchHeaders = Object.assign({ Authorization: `Bearer ${token}` }, headers);
            const fetchOptions = Object.assign({ headers: fetchHeaders }, options);
            return fetch(url, fetchOptions);
        }).catch(console.error);
    }

    checkRoles(role) {
        return this.user && this.user.profile && this.user.profile.roles.includes(role);
    }

    updateUi() {
        if (this.user) {
            document.getElementById("user-name").innerHTML = this.user.profile.name;
            document.getElementById("user-box").style.display = 'block';
            document.getElementById("user-info").title = this.user.profile.roles.join(', ');
            if (this.checkRoles("Admin")) {
                document.getElementById("role-icon").classList.add("im-support");
            } else if (this.checkRoles("User")) {
                document.getElementById("role-icon").classList.add("im-user-circle");
            }
        }
    }
}
