import { Settings } from './settings';
import { Utils } from './utils';

export class StoryTellerUI {
    constructor() {
        if (Settings.ui.showDebugDialogueInfo) {
            this.UpdateDialogueLine({
                Line: {
                    MasterText: "Debugging Master Text"
                },
                Character: {
                    Name: "Debugging Character Name",
                    Gender: "Debugging Gender",
                },
                Parenthetical: "Debugging Emotion"
            });
        }

        this.SetElementInnerHTML("appVersionLabel", "Vrs. " + Settings.appVersion);

        if(!Utils.isBrowserFullySupported()) {
            document.getElementById("supportTab").style.visibility = "visible";
            document.getElementById("supportTabContent").style.visibility = "visible";
            document.getElementById("supportTabContentLimitations").innerHTML = "";

            var limitations = Utils.enumerateLimitations();

            limitations.forEach(limitation => {
                var element = document.getElementById("supportTabContentLimitations").appendChild(document.createElement("div"));
                element.classList.add("st-browser-limitation");

                var title = element.appendChild(document.createElement("div"));
                title.classList.add("st-browser-limitation-title");
                title.innerText = limitation.reason;

                var description = element.appendChild(document.createElement("div"));
                description.classList.add("st-browser-limitation-description");
                description.innerText = limitation.description;
                
            });

            document.getElementById("supportTabTitle").innerText = "Support (" + limitations.length + ")";
        }
    }

    SetWebSocket(webSocket) {
        this.webSocket = webSocket;
    }

    UpdateDialogueLine(dialogue) {

        if (dialogue) {
            this.SetOrReplaceClass("scenePanel", "", ["st-hidden"]);
            this.SetOrReplaceClass("linePanel", "", ["st-hidden"]);
            this.SetOrReplaceClass("waitingStoryTellerPanel", "st-hidden");
            this.SetElementInnerHTML("dialogueLineLabel", dialogue.Line.MasterText);

            if (dialogue.Character) {
                this.SetElementInnerHTML("dialogueActorLabel", dialogue.Character.Name);
                switch (dialogue.Character.Gender) {
                    case 0: this.SetElementInnerHTML("dialogueGenderLabel", "Not Defined"); break;
                    case 1: this.SetElementInnerHTML("dialogueGenderLabel", "Male"); break;
                    case 2: this.SetElementInnerHTML("dialogueGenderLabel", "Female"); break;
                    default: this.SetElementInnerHTML("dialogueGenderLabel", dialogue.Character.Gender); break;
                }
            } else {
                this.SetElementInnerHTML("dialogueActorLabel");
                this.SetElementInnerHTML("dialogueGenderLabel");
            }

            this.SetElementInnerHTML("dialogueEmotionLabel", dialogue.Parenthetical);
        } else {
            this.SetOrReplaceClass("scenePanel", "st-hidden");
            this.SetOrReplaceClass("linePanel", "st-hidden");
            this.SetOrReplaceClass("waitingStoryTellerPanel", "", ["st-hidden"]);
        }
    }

    UpdateScene(sceneName) {
        this.SetElementInnerHTML("sceneLabel", sceneName);
    }

    OnWebSocketConnected(owner) {
        owner.SetElementInnerHTML("connectionStatusLabel", "Connected");
        owner.SetOrReplaceClass("connectionStatusLabel", "st-badge-connected", ["st-badge-connecting", "st-badge-disconnected"]);
    }

    OnWebSocketDisconnected(owner) {
        owner.SetElementInnerHTML("connectionStatusLabel", "Not Connected");
        owner.SetOrReplaceClass("connectionStatusLabel", "st-badge-disconnected", ["st-badge-connecting", "st-badge-connected"]);
    }

    OnWebSocketConnecting(owner) {
        owner.SetElementInnerHTML("connectionStatusLabel", "Connecting");
        owner.SetOrReplaceClass("connectionStatusLabel", "st-badge-connecting", ["st-badge-connected", "st-badge-disconnected"]);
    }

    OnStartStreaming(owner) {
    }

    OnStopStreaming(owner) {
    }
    
    OnAuthorizationUpdate(owner, data) {
        if(data.Authorized) {
            owner.SetOrReplaceClass("authorizationPanel", "st-hidden");
            document.getElementById("requestApiKeyForExternApp").disabled = false;
        } else {
            document.getElementById("requestApiKeyForExternApp").disabled = true;
            owner.SetOrReplaceClass("waitingStoryTellerPanel", "st-hidden");
            owner.SetOrReplaceClass("authorizationPanel", "", ["st-hidden"]);
            owner.SetElementInnerHTML("authorizationLabel", data.Details);
        }
    }

    OnServerVersion(owner, data) {
        owner.SetElementInnerHTML("serverVersionLabel", data);
        StoryTeller.media.StartStreaming();
    }

    OnRecordingSessionUpdate(session) {
        this.SetElementInnerHTML("recordingSessionNameLabel", session.Name);
        var participantListHtml = this.SetElementInnerHTML("participantList", "");
        for(var i = 0; i < session.Participants.length; ++i) {
            var participant = session.Participants[i];
            var participantHtml = participantListHtml.appendChild(document.createElement("div"));
            if(participant.Muted == true) {
                participantHtml.innerHTML = `<i class="im im-microphone st-participant-icon st-mic-status-off" height="16" width="16"></i> ` + participant.Name;
            }
            else if(participant.Muted == false) {
                participantHtml.innerHTML = `<i class="im im-microphone st-participant-icon st-mic-status-on" height="16" width="16"></i> ` + participant.Name;
            }
            else {
                participantHtml.innerHTML = participant.Name;
            }
            participantHtml.classList.add("st-recordingsession-participant");
        }
    }

    OnServerNotification(owner, message) {
        var method = message.method;

        switch (method) {
            case "OnSelectedLineChanged":
                owner.UpdateDialogueLine(message.data);
                break;
            case "OnSelectedSceneChanged":
                owner.UpdateScene(message.data);
                break;
            case "VABBRR.SessionManager.Sessions":
                owner.OnRecordingSessionUpdate(message.data);
                break;
        }
    }

    SetElementInnerHTML(name, html) {
        var element = document.getElementById(name);
        if (html) {
            element.innerHTML = html;
        } else {
            element.innerHTML = "";
        }
        return element;
    }

    SetOrReplaceClass(name, newClass, oldClasses) {
        var element = document.getElementById(name);
        if (oldClasses) {
            for (var i = 0; i < oldClasses.length; ++i) {
                element.classList.remove(oldClasses[i]);
            }
        }

        if (newClass != "" && !element.classList.contains(newClass)) {
            element.classList.add(newClass);
        }
    }

    OnBufferChange(frames, frameSize, maxFrames, isFull) {
        var progressBar = document.getElementById("currentBufferProgressBar");
        progressBar.max = maxFrames;
        progressBar.value = frames;

        this.SetElementInnerHTML("currentBufferSizeLabel", frames * frameSize + " of " + maxFrames * frameSize);
    }

    OnDevicesReady(inputDevices, selectedInputDevice, outputDevices, selectedOutputDevice) {
        var inputDevicesList = document.getElementById("inputDevicesList");
        var outputDevicesList = document.getElementById("outputDevicesList");

        inputDevicesList.innerHTML = "";
        outputDevicesList.innerHTML = "";

        for (var i = 0; i < inputDevices.length; ++i) {
            var option = document.createElement("option");
            option.text = inputDevices[i].label || "Microphone " + (inputDevicesList.childElementCount + 1);
            option.deviceInfo = inputDevices[i];
            inputDevicesList.appendChild(option);

            if(option.deviceInfo.deviceId === selectedInputDevice.deviceId) {
                inputDevicesList.selectedIndex = i;
            }
        }

        for (var i = 0; i < outputDevices.length; ++i) {
            var option = document.createElement("option");
            option.text = outputDevices[i].label || "Speaker " + (outputDevicesList.childElementCount + 1);
            option.deviceInfo = outputDevices[i];
            outputDevicesList.appendChild(option);

            if(option.deviceInfo.deviceId === selectedOutputDevice.deviceId) {
                outputDevicesList.selectedIndex = i;
            }
        }
    }

    OnSelectInputDevice() {
        var inputDevicesList = document.getElementById("inputDevicesList");
        var inputDevice = inputDevicesList.options[inputDevicesList.selectedIndex];

        if(inputDevice.deviceInfo) {
            StoryTeller.media.SelectInputDevice(inputDevice.deviceInfo);
        }
    }

    OnSelectOutputDevice() {
        var outputDevicesList = document.getElementById("outputDevicesList");
        var outputDevice = outputDevicesList.options[outputDevicesList.selectedIndex];

        if(outputDevice.deviceInfo) {
            StoryTeller.media.SelectOutputDevice(outputDevice.deviceInfo);
        }
    }

    OnUploadDegradation(owner, compromised, level) {
        if (compromised) {
            if (level > 1) {
                owner.SetOrReplaceClass("uploadStatusIcon", "st-icon-bad", ["st-icon-ok", "st-icon-warn"]);
            } else {
                owner.SetOrReplaceClass("uploadStatusIcon", "st-icon-warn", ["st-icon-ok", "st-icon-bad"]);
            }
        } else {
            owner.SetOrReplaceClass("uploadStatusIcon", "st-icon-ok", ["st-icon-warn", "st-icon-bad"]);
        }
    }

    OnDownloadDegradation(owner, compromised) {
        if (compromised) {
            owner.SetOrReplaceClass("downloadStatusIcon", "st-icon-warn", ["st-icon-ok", "st-icon-bad"]);
        } else {
            owner.SetOrReplaceClass("downloadStatusIcon", "st-icon-ok", ["st-icon-warn", "st-icon-bad"]);
        }
    }

    OnMuteToggled(owner, isMuted) {
        if(isMuted) {
            owner.SetOrReplaceClass("muteBtn", "st-mic-off", ["st-mic-on"]);
        } else {
            owner.SetOrReplaceClass("muteBtn", "st-mic-on", ["st-mic-off"]);
        }
    }

    OnBufferingInfo(owner, bufferSize, bufferInUse, isBuffering, bufferingCount) {
        owner.SetElementInnerHTML("bufferingInfoLabelL1", isBuffering ? "Is Buffering" : "");
        owner.SetElementInnerHTML("bufferingInfoLabelL2", "Buffered:  " + bufferInUse + " of " + bufferSize);
        owner.SetElementInnerHTML("bufferingCountLabel", "Nr. Buffering:  " + bufferingCount);
    }

    OnLatencyUpdate(owner, warn, frames, accDrift, drift, elapsed) {
        owner.SetElementInnerHTML("receivedFramesLabel", "Received Frames: " + frames);
        owner.SetElementInnerHTML("accumalatedDriftLabel", "Total Buffer Drift: " + accDrift.toFixed(2) + " ms");
        owner.SetElementInnerHTML("elapsedTimeLabel", "Elapsed Time: " + elapsed.toFixed(2) + " ms");
    }

    OnDSPLatencyUpdate(owner, warn, frames, accDrift, drift, elapsed) {
        owner.SetElementInnerHTML("dspReceivedFramesLabel", "Frames: " + frames);
        owner.SetElementInnerHTML("dspAccumalatedDriftLabel", "Total Time Drift: " + accDrift.toFixed(2) + " ms");
        owner.SetElementInnerHTML("dspElapsedTimeLabel", "Elapsed Time: " + elapsed.toFixed(2) + " ms");
    }
}

