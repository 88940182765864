import {} from './redirectConsole'
import { Settings } from './settings';
import { StoryTellerMedia } from './media';
import { StoryTellerUI } from './ui';
import { AdalAuthenticator } from './auth';
import { StoryTellerWebSocket } from './websocket';
import { Utils } from './utils';

globalThis.StoryTeller = {}
globalThis.Settings = Settings;

globalThis.AdalAuthenticator = new AdalAuthenticator({
    clientId: process.env.AAD_CLIENT_ID,
    tenant: process.env.AAD_TENANT_ID,
    resources: JSON.parse(process.env.AAD_RESOURCES || [])
});
globalThis.AdalAuthenticator.login();

window.onload = function () {
    if(Settings.redirectConsole || Utils.getUrlParam("redirectConsole") === "true") {
        window.setupConsoleRedirection();
    }

    document.getElementById("webServiceAddress").value = Settings.serverAddress;

    StoryTeller.ui = new StoryTellerUI();
    StoryTeller.media = new StoryTellerMedia(StoryTeller.ui);
    StoryTeller.RestartConnection();

    StoryTeller.media.OnDevicesReady = StoryTeller.ui.OnDevicesReady;
    StoryTeller.media.Initialize();

    globalThis.AdalAuthenticator.updateUi();
}

StoryTeller.RestartConnection = function () {
    if (StoryTeller.webSocket) {
        StoryTeller.webSocket.Close();
    }

    var promiseToken = globalThis.AdalAuthenticator.fetchToken(Settings.serverAddress);
    promiseToken.then(t => {
        StoryTeller.webSocket = new StoryTellerWebSocket(StoryTeller.ui, StoryTeller.media, t);
        StoryTeller.media.SetWebSocket(StoryTeller.webSocket);
        StoryTeller.ui.SetWebSocket(StoryTeller.webSocket);

        StoryTeller.webSocket.Connect(t);
    });
}
