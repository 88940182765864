import { Settings } from './settings';
import { ReSampler } from './resampler';
import { LatencyCheck } from './latencyCheck';
import { Utils } from './utils';

var AudioContext = window.AudioContext || window.webkitAudioContext; 

export class ProtocolInformation {
    constructor() {
        this.ApiToken = "";
        this.WebAddress = "";
    }
}

export class StoryTellerMedia {
    constructor(ui) {
        this.constraints = {
            audio: { sampleRate: Settings.media.sampleRate },
            video: false,
        };

        var that = this;

        this.ui = ui;
        this.isMuted = false;
        this.isStreaming = false;

        this.OnStartStreaming = function() { ui.OnStartStreaming(ui); }
        this.OnStopStreaming = function() { ui.OnStopStreaming(ui); }
        this.ClearBuffers();
        
        if(Settings.media.resample) {
            this.resampler = new ReSampler();
        }
        
        if(Settings.media.latencyCheck || Utils.getUrlParam("latencyCheck") === "true") {
            this.latencyCheck = new LatencyCheck(Settings.media.sampleRate, Settings.media.maxBufferSize, function(warn, frames, accDrift, drift, elapsed) {
                that.ui.OnLatencyUpdate(that.ui, warn, frames, accDrift, drift, elapsed);
            });
        }

        if(Settings.media.dspLatencyCheck || Utils.getUrlParam("dspLatencyCheck") === "true") {
            this.dspLatencyCheck = new LatencyCheck(Settings.media.sampleRate, Settings.media.maxBufferSize, function(warn, frames, accDrift, drift, elapsed) {
                that.ui.OnDSPLatencyUpdate(that.ui, warn, frames, accDrift, drift, elapsed);
            });
        }
    }

    SetWebSocket(webSocket) {
        this.webSocket = webSocket;
        this.webSocket.mediaMessageOwner = this;
        this.webSocket.OnMediaMessage = this.OnInputReceivedFromServer;
    }

    Initialize() {
        var that = this;
        window.navigator.mediaDevices.getUserMedia(this.constraints).then(stream => {
            that.stream = stream;
            return window.navigator.mediaDevices.enumerateDevices();
        }).then(deviceInfos => {
            if (!that.OnDevicesReady) {
                return;
            }

            var inputDevices = [];
            var outputDevices = [];

            for (var i = 0; i !== deviceInfos.length; ++i) {
                var deviceInfo = deviceInfos[i];
                if (deviceInfo.kind === 'audioinput') {
                    if (!that.inputDeviceInfo || Cookies.get('inputDevice') == deviceInfo.deviceId) {
                        that.inputDeviceInfo = deviceInfo;
                    }
                    inputDevices.push(deviceInfo);
                } else if (deviceInfo.kind === 'audiooutput') {
                    if (!that.outputDeviceInfo || Cookies.get('outputDevice') == deviceInfo.deviceId) {
                        that.outputDeviceInfo = deviceInfo;
                    }
                    outputDevices.push(deviceInfo);
                }
            }
            that.OnDevicesReady(inputDevices, that.inputDeviceInfo, outputDevices, that.outputDeviceInfo);
        }).catch(this.HandleError);
    }

    HandleError(error) {
        console.error(error);
    }

    SelectInputDevice(deviceInfo) {
        this.inputDeviceInfo = deviceInfo;
        Cookies.set('inputDevice', deviceInfo.deviceId);
        if (this.isStreaming) {
            this.StartStreaming();
        }
    }

    SelectOutputDevice(deviceInfo) {
        this.outputDeviceInfo = deviceInfo;
        Cookies.set('outputDevice', deviceInfo.deviceId);
        if (this.isStreaming) {
            this.StartStreaming();
        }
    }

    StartStreaming() {
        this.StopStreaming();

        var that = this;
        var constraints = Object.assign({}, this.constraints);

        if(this.inputDeviceInfo) {
            constraints.audio.deviceId = this.inputDeviceInfo.deviceId;
        }

        window.navigator.mediaDevices.getUserMedia(constraints).then(stream => {
            that.selectedInputStream = stream;

            that.ClearBuffers();
            that.CreateAudioContext();
            that.StartRecording();
            that.StartPlayback();

            that.isStreaming = true;

            if (that.OnStartStreaming) {
                that.OnStartStreaming();
            }

        }).catch(this.HandleError);
    }

    StopStreaming() {
        if (this.isStreaming) {
            if (this.audioContext) {
                this.audioContext.close();
                this.audioContext = null;
            }

            this.gain_node = null;
            this.script_processor_node = null;
            this.microphone_stream = null;

            this.StopPlayback();

            this.isStreaming = false;

            if (this.OnStopStreaming) {
                this.OnStopStreaming();
            }
        }
    }
    
    RequestApiKey() {
        var that = this;
        this.webSocket.RPC("VABBRR.GetApiKey", function(result) {
            if(result.Success == true) {           
                var protocolInfo = new ProtocolInformation();
                protocolInfo.ApiToken = result.Key;
                protocolInfo.WebAddress = Settings.serverAddress;
                
                var json = JSON.stringify(protocolInfo);
                var jsonBase64 = btoa(json);
                
                window.location.href = "vabbrr://" + jsonBase64 + "/";
            }
        });
    }

    ClearBuffers() {
        this.silenceBuffer = new Float32Array(Settings.media.maxBufferSize);
        this.buffering = true;
        this.bufferingCount = 1;
        this.inputFramesQueue = [];
    }

    CreateAudioContext() {
        this.audioContext = new AudioContext({ latencyHint: Settings.media.latencyHint, sampleRate: Settings.media.sampleRate });
    }

    StartRecording() {
        this.script_processor_node = this.audioContext.createScriptProcessor(Settings.media.maxBufferSize, 1, 1);
        this.script_processor_node.owner = this;
        this.script_processor_node.onaudioprocess = this.ProcessLocalInputBuffer;
        this.script_processor_node.connect(this.audioContext.destination);

        this.microphone_stream = this.audioContext.createMediaStreamSource(this.selectedInputStream);
        this.microphone_stream.connect(this.script_processor_node);

        return this.audioContext;
    }

    ProcessLocalInputBuffer(event) {
        if (this.owner.webSocket.connected) {

            var inputBufferData = event.inputBuffer.getChannelData(0);

            if(this.owner.isMuted) {
                if(Settings.media.muteSendSilence) {
                    inputBufferData = this.owner.silenceBuffer;
                } else {
                    return;
                }
            }

            if(this.owner.resampler) {
                this.owner.webSocket.Send(this.owner.resampler.encode(inputBufferData));
            } else {
                this.owner.webSocket.Send(inputBufferData);
            }
        }
    }

    StopPlayback() {
        this.outputGainNode = null;
        this.scriptProcessorNode = null;
    }

    StartPlayback() {
        this.StopPlayback();
        
        if(this.latencyCheck) {
            this.latencyCheck.Reset();
        }

        if(this.dspLatencyCheck) {
            this.dspLatencyCheck.Reset();
        }

        var that = this;
        var constraints = Object.assign({}, this.constraints);

        if(this.outputDeviceInfo) {
            constraints.audio = { deviceId: this.outputDeviceInfo.deviceId };
        }

        window.navigator.mediaDevices.getUserMedia(constraints).then(stream => {
            that.selectedOuputStream = stream;

            that.speakerStreamNode = that.audioContext.createMediaStreamDestination(that.selectedOuputStream);
            that.scriptProcessorNode = that.audioContext.createScriptProcessor(Settings.media.maxBufferSize, 0, 1);

            that.scriptProcessorNode.onaudioprocess = function (event) {
            
                if(that.dspLatencyCheck) {
                    that.dspLatencyCheck.Run();
                }

                if (that.buffering) {
                    event.outputBuffer.copyToChannel(that.silenceBuffer, 0, 0);
                    if (!that.downloadDegradation) {
                        that.ui.OnDownloadDegradation(that.ui, true);
                        that.downloadDegradation = true;
                    }
                } else if (that.inputFramesQueue.length > 0) {
                    event.outputBuffer.copyToChannel(that.inputFramesQueue.shift(), 0, 0);
                    if (that.downloadDegradation) {
                        that.ui.OnDownloadDegradation(that.ui, false);
                        that.downloadDegradation = false;
                    }
                } else {
                    event.outputBuffer.copyToChannel(that.silenceBuffer, 0, 0);
                    that.buffering = true;
                    that.bufferingCount++;
                    if (!that.downloadDegradation) {
                        that.ui.OnDownloadDegradation(that.ui, true);
                        that.downloadDegradation = true;
                    }
                }
            }

            that.scriptProcessorNode.connect(that.speakerStreamNode);

            var audioHook = document.getElementById("audioHook");
            audioHook.srcObject = that.speakerStreamNode.stream;
            if(that.outputDeviceInfo) {
                audioHook.setSinkId(that.outputDeviceInfo.deviceId);
            }
            audioHook.play();

        }).catch(this.HandleError);
    }

    OnInputReceivedFromServer(owner, data) {
        if(!owner.isStreaming) {
            return;
        }

        if(owner.latencyCheck) {
            owner.latencyCheck.Run();
        }

        if (Settings.media.allowDropPlaybackFrames && owner.inputFramesQueue.length >= Settings.media.maxFramesToKeep && !owner.buffering) {
            owner.ui.OnBufferingInfo(owner.ui, Settings.media.framesToBuffer, owner.inputFramesQueue.length, owner.buffering);
            return;
        }

        if(owner.resampler) {
            owner.inputFramesQueue.push(owner.resampler.decode(data));
        } else {
            owner.inputFramesQueue.push(new Float32Array(data.slice(0)));
        }

        if(owner.buffering && owner.inputFramesQueue.length >= Settings.media.framesToBuffer) {
            owner.buffering = false;
        } 

        owner.ui.OnBufferingInfo(owner.ui, Settings.media.framesToBuffer, owner.inputFramesQueue.length, owner.buffering, owner.bufferingCount);
    }

    ToggleMute() {
        this.isMuted = !this.isMuted;
        this.ui.OnMuteToggled(this.ui, this.isMuted);
        
        this.NotifyMute();
    }
    
    NotifyMute() {
        this.webSocket.RPC("VABBRR.NotifyMute", null, this.isMuted);
    }
    
    OnAuthorized() {
        this.NotifyMute();
    }
}

