import { Settings } from './settings';
export class StoryTellerWebSocket {
    constructor(ui, media, token) {
        this.ui = ui;
        this.media = media;
        this.token = token;
        this.authorized = false;
        this.rpcCalls = 1000;
        this.rpcCallbacks = [];
    }

    Connect(token) {    
        var that = this;
        this.webSocket = new WebSocket(Settings.serverAddress);
        this.webSocket.binaryType = "arraybuffer";

        if(!this.closed) {
            this.ui.OnWebSocketConnecting(this.ui);
        }

        this.webSocket.onopen = function(e) {
            if(that.closed) {
                return;
            }

            that.connected = true;
            that.webSocket.send(that.token);
        };
    
        this.webSocket.onclose = function(e) {
            if(that.closed) {
                return;
            }
            
            that.connected = false;
            that.authorized = false;
            that.ui.OnWebSocketDisconnected(that.ui);

            if (Settings.allowReconnect) {
                that.Connect(that.token);
            }
        }

        this.webSocket.onmessage = function(e) {
            if(that.closed) {
                return;
            }

            // Authorization check
            if(!that.authorized) {
                if(typeof e.data == "object") {
                    // we don't want non authorized media streams
                    return;
                }

                var message = JSON.parse(e.data);
                if(message && message.Name == "AuthorizationRequest") {
                    that.authorized = message.Authorized;

                    if(that.authorized) {
                        that.ui.OnWebSocketConnected(that.ui);
                    }

                    that.ui.OnAuthorizationUpdate(that.ui, message);

                    // requesting the server version
                    that.RPC("VABBRR.GetVersion", function(result) {
                        that.serverVersion = result.Version;
                        that.ui.OnServerVersion(that.ui, result.Version);
                    });
                    that.media.OnAuthorized();
                }
                return;
            }

            // Authorized messages...
            if(typeof e.data == "object") {
                if(that.OnMediaMessage) {
                    that.OnMediaMessage(that.mediaMessageOwner, e.data);
                }
            } else {
                var message = JSON.parse(e.data);
                if(that.IsJsonRPC(message)) {
                    that.HandleRPCCallback(message);
                } else {
                    console.error("Invalid message received: " + e.data);
                }
            }
        }
    }

    OnDegradation(compromised, level) {
        this.ui.OnUploadDegradation(this.ui, compromised, level);
    }

    Close() {
        this.closed = true;
        this.webSocket.close();
        this.webSocket = null;
        this.connected = false;
        this.authorized = false;
    }

    Send(data) {
        if (this.connected && this.authorized) {
            if (Settings.webSocket.checkForDegradation) {
                if (this.webSocket.bufferedAmount > Settings.webSocket.bufferedAmountL1) {
                    if (!this.degradationL1) {
                        this.OnDegradation(true, 1);
                    }
                    this.degradationL1 = true;
                    if (this.webSocket.bufferedAmount > Settings.webSocket.bufferedAmountL2) {
                        if (!this.degradationL2) {
                            this.OnDegradation(true, 2);
                        }
                        this.degradationL2 = true;
                    }
                } else {
                    if (this.degradationL1) {
                        this.OnDegradation(false, 0);
                    }
                    this.degradationL1 = this.degradationL2 = false;
                }
            }

            this.webSocket.send(data);
        }
    }

    RPC(methodName, onResultCallback, params, onErrorCallback) {
        var message = {
            jsonrpc: "2.0",
            method: methodName,
            token: this.rpcCalls.toString(),
            sender: "client",
            target: "server",
            params: params,
        }
        
        this.rpcCallbacks[this.rpcCalls++] = [onResultCallback, onErrorCallback];
        this.Send(JSON.stringify(message));
    }

    IsJsonRPC(message) {
        return message != undefined && (message.result || message.method);
    }

    HandleRPCCallback(message) {
        if(message.result != undefined) {
            var callback = this.rpcCallbacks[message.token][message.error ? 1 : 0];
            delete this.rpcCallbacks[message.token];
            if(callback) {
                callback(message.result);
            }
        } else {
            this.ui.OnServerNotification(this.ui, message);
        }
    }
    
}
