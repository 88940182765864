export class LatencyCheck {
    constructor(sampleRate, frameSize, onDriftingUpdate) {
        this.framesWindow = 48;
        this.frameInterval = (this.framesWindow / (sampleRate / frameSize)) * 1000;
        this.warningDifference = this.frameInterval * 0.15;
        this.onDriftingUpdate = onDriftingUpdate;
        this.Reset();
    }

    Run() {
        this.frames++;
        if(this.frames % this.framesWindow == 0) {
            var receivedFrame = performance.now();
            var elapsedTime = receivedFrame - this.lastReceivedFrame;
            this.lastReceivedFrame = receivedFrame;
            this.elapsedTime += elapsedTime;

            var diff = elapsedTime - this.frameInterval; 
            this.accumulatedDrift += diff;

            if(this.onDriftingUpdate) {
                this.onDriftingUpdate(Math.abs(diff) > this.warningDifference, this.frames, this.accumulatedDrift, diff, this.elapsedTime);
            } else {
                if(Math.abs(diff) > this.warningDifference) {
                    this.warnedFrames++;
                    console.warn((this.frames - 1) + ": " + this.accumulatedDrift + " (" + diff + ", " + this.elapsedTime + ")");
                } else {
                    console.log((this.frames - 1) + ": " + this.accumulatedDrift + " (" + diff + ", " + this.elapsedTime + ")");
                }
            }
        }
    }

    Reset() {
        this.lastReceivedFrame = performance.now();
        this.accumulatedDrift = 0;
        this.frameSkip = 10;
        this.frames = 0;
        this.warnedFrames = 0;
        this.elapsedTime = 0;
    }
}