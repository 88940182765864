/**
 * Signed 16 Bit Simple Re-Sampler (Change Format)
 */
export class ReSampler {
    constructor() {
    }


    /**
     * Converts the AudioBuffer channel data to Signed 16 bit
     * @param {Float32Array} buffer AudioBuffer Channel data 
     * @returns {ArrayBuffer} The converted buffer
     */
    encode(buffer) {
        if(!this.encodingBuffer) {
            this.encodingBuffer = new ArrayBuffer(buffer.byteLength / 2);
            this.encodingBufferView = new Int16Array(this.encodingBuffer);
        }

        for(var i = 0; i < buffer.byteLength; ++i) {
            this.encodingBufferView[i] = 32767 * buffer[i];
        }

        return this.encodingBuffer;
    }

    /**
     * Re-creates the AudioBuffer channel data from re-sampled data
     * @param {ArrayBuffer} buffer Previously encoded data
     * @returns {Float32Array} AudioBuffer Float32Array compatible buffer
     */
    decode(buffer) {
        if(!this.decodingBuffer) {
            this.decodingBuffer = new Float32Array(buffer.byteLength / 2);
        }

        var view = new Int16Array(buffer);
        for(var i = 0; i < view.length; ++i) {
            this.decodingBuffer[i] = view[i] / 32767.0;
        }

        return this.decodingBuffer;
    }
}