import { Settings } from "./settings";

window.setupConsoleRedirection = function() {
    document.getElementById("outputConsole").style.visibility = "visible";
    window.addToVisualConsole = function(type, text) {
        var output = document.getElementById("outputConsole");
        var line = output.appendChild(document.createElement("div"));
        line.innerText = "[" + (new Date()).toLocaleTimeString() + "][" + type + "]: " + text;

        while(output.childElementCount > Settings.maxRedirectConsoleLines) {
            output.removeChild(output.firstElementChild);
        }
    }

    var console=(function(oldCons){
        return {
            log: function(text){
                oldCons.log(text);
                window.addToVisualConsole("LOG", text);
            },
            info: function (text) {
                oldCons.info(text);
                window.addToVisualConsole("INFO", text);
            },
            warn: function (text) {
                oldCons.warn(text);
                window.addToVisualConsole("WARN", text);
            },
            error: function (text) {
                oldCons.error(text);
                window.addToVisualConsole("ERROR", text);
            },
            clear: function() {
                oldCons.clear();
            }
        };
    }(window.console));
    window.console = console;
}
