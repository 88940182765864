export const Settings = {
    allowReconnect: true,
    appVersion: process.env.APP_VERSION_PROD,
    redirectConsole: process.env.APP_VERSION_PROD == "DEBUG" || process.env.APP_VERSION_PROD === "",
    media: {
        allowDropPlaybackFrames: true,
        dspLatencyCheck: process.env.APP_VERSION_PROD == "DEBUG" || process.env.APP_VERSION_PROD === "",
        framesToBuffer: 4,
        latencyCheck: process.env.APP_VERSION_PROD == "DEBUG" || process.env.APP_VERSION_PROD === "",
        latencyHint: "interactive",
        maxBufferSize: 1024,
        maxFramesToKeep: 2 * 8,
        muteSendSilence: false,
        resample: false,
        sampleRate: 48000,
    },
    maxRedirectConsoleLines: 1000,
    serverAddress: process.env.WS_SERVER_ENDPOINT, 
    ui: {
        showDebugDialogueInfo: false,
    },
    webSocket: {
        bufferedAmountL1: 0,
        bufferedAmountL2: 1024 * 256,
        checkForDegradation: true,
    }
};

