export class Limitation {
    constructor(reason, description) {
        this.reason = reason;
        this.description = description;
    }
}

Limitation.playbackDeviceSelection = new Limitation("Playback Device Selection", "Playback device selection is not supported in this browser. Audio will be always played using the system's default playback device.");
Limitation.notFullyTested = new Limitation("Browser not fully tested", "Features on this browser may or may not work. The browser wasn't fully tested and should be used with caution.");
Limitation.mobileDevice = new Limitation("Mobile device detected", "Mobile devices are subject to performance issues and may not work as expected.");

export class Utils {
    static isFirefox() {
        return window.navigator.userAgent.match(/Firefox\/([0-9]+)\./);
    }

    static isChrome() {
        return /Chrome/.test(window.navigator.userAgent) && /Google Inc/.test(window.navigator.vendor);
    }

    static isEdge() {
        return /Edge/.test(window.navigator.userAgent);
    }

    static isSafari() {
        return /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);
    }

    static isMobile() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/.test(window.navigator.userAgent);
    }

    static isBrowserFullySupported() {
        return (this.isChrome() || this.isEdge() || this.isSafari()) && !this.isMobile();
    }

    static enumerateLimitations() {
        var limitations = [];
        
        if(this.isFirefox()) {
            limitations.push(Limitation.playbackDeviceSelection);
        }

        if(this.isMobile()) {
            limitations.push(Limitation.mobileDevice);
        }

        if(!this.isBrowserFullySupported()) {
            limitations.push(Limitation.notFullyTested);
        }

        return limitations;
    }

    static getUrlParam(name) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(name);
    }
}